import React, { Component } from "react"
import * as styles from "../../../common/welcome.module.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"
import backgroundImage from "../../../../../../assets/header-bannerbg-ironcapx.png"
import backgroundImageMin from "../../../../../../assets/header-bannerbg-ironcapx-min.png"
import ProgressiveImage from "react-progressive-image-loading"

import imgOutlook from "../../../../../../assets/outlook-integration.png"
import imgBrowser from "../../../../../../assets/browser-integration.png"
import imgUserManagement from "../../../../../../assets/userManagement.png"
import { TM } from "../../../../../components/common/typography/trademark"

class IronCAPXVersion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktopOrTablet: true,
    }
  }
  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  render() {
    return (
      <div
        style={{
          margin: `0 auto`,
          maxWidth: "86%",
          paddingTop: 0,
        }}
      >
        <ProgressiveImage
          preview={backgroundImageMin}
          src={backgroundImage}
          transitionTime={500}
          transitionFunction="ease"
          render={(src, style) => (
            <div
              className={styles.headerBanner}
              style={Object.assign(style, { backgroundImage: `url(${src})` })}
            >
              <h1>
                Welcome to IronCAP X<TM />
              </h1>
            </div>
          )}
        />
        <div className={styles.pageContainer}>
          <div className={styles.pageText}>
            <p>
              Your IronCAP X<TM /> account is now ready for use on the device
              that you have installed the IronCAP X<TM /> software. If you use
              more than one device to send or receive messages for the same
              email address, please make sure you have IronCAP X
              <TM /> installed on them as well.
            </p>
            <p>
              You can now exchange secure emails with other IronCAP X<TM />{" "}
              users and safeguard yourselves against cyber attacks not only from
              conventional computers but also those from future quantum
              computers.
            </p>

            <h3>
              Central Management tool{" "}
              <span style={{ fontWeight: "normal" }}>
                (for Business accounts)
              </span>
            </h3>

            <p>
              An IronCAP X<TM /> business account comes with a Central
              Management tool for the IT administrator to manage all the users
              under the account. Using the Central Management tool, the IT
              administrator can create and delete users as well as manage the
              key for each one of them such as to reset password, revoke the
              key, etc. In other words, all the credentials are under the
              control of the IT administrator, not the individual users under
              the account.
            </p>
            <img
              className={styles.imageContainer}
              src={imgUserManagement}
            ></img>

            <h3>
              Sending encrypted emails to non-IronCAP X<TM /> users
            </h3>
            <p>
              Recipients who are not IronCAP X<TM /> users will receive your
              encrypted message together with instructions on how to sign up for
              a FREE IronCAP X<TM /> account in order to open it.
            </p>

            <h3>Encrypt and/or digitally sign your emails</h3>
            <p>
              IronCAP X<TM /> integrates with popular email clients to ensure
              you are up and running in no time. As easy as 1-2-3, you can send
              encrypted emails by selecting the IronCAP X<TM /> encryptions
              options on the compose screen. It's that simple!
            </p>
            <h4>Outlook Integration</h4>
            <img className={styles.imageContainer} src={imgOutlook}></img>

            <h4>
              Browser Integration{" "}
              <span style={{ fontWeight: 500, fontSize: 14 }}>
                (IronCAP browser app required)
              </span>
            </h4>

            <img className={styles.imageContainer} src={imgBrowser}></img>

            <h3>Encrypt and/or digitally sign your files</h3>
            <p>
              IronCAP X<TM /> integrates seamlessly with the file system of your
              computer. Simply right-mouse click on the file name you wish to
              encrypt or digitally sign and then select the IronCAP X
              <TM /> options.
            </p>

            <h4>Links to Important Information</h4>
            <ul className={styles.linkContainer}>
              <OutboundLink
                href="https://www.01com.com/01com/ironcap-x/webhelp/index.htm#t=IronCAP_X%2FMy_Account%2Flaunch_My_Account.htm"
                target="_blank"
              >
                <li>Manage your account</li>
              </OutboundLink>

              <OutboundLink
                href="https://www.01com.com/01com/ironcap-x/webhelp/index.htm#t=IronCAP_X%2FMy_Account%2FMy_Account_-_FREE.htm"
                target="_blank"
              >
                <li>Upgrade to Business Account</li>
              </OutboundLink>

              <OutboundLink
                href="https://www.01com.com/01com/ironcap-x/webhelp/index.htm"
                target="_blank"
              >
                <li>User's Guide</li>
              </OutboundLink>
              <Link to="/ironcap-x/faq/">
                <li>FAQ</li>
              </Link>
            </ul>

            <p style={{ fontSize: 16, marginTop: "2rem" }}>
              IronCAP X<TM /> services require agreement to our{" "}
              <OutboundLink
                href="https://www.01com.com/pdf/ironcapx-terms-of-service.pdf"
                target="_blank"
              >
                Terms of Service{" "}
              </OutboundLink>
              and{" "}
              <OutboundLink
                href="https://www.01com.com/pdf/ironcapx-privacy-policy.pdf"
                target="_blank"
              >
                Privacy Policy.
              </OutboundLink>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default IronCAPXVersion
